import { renderRichText, useStoryblokState, ISbStoryParams, getStoryblokApi } from '@storyblok/react';
import { relations } from '~/components/bloks';

/**
 * This is a high component to wrap next.js pages that are set in Storyblok
 * This is useful to avoid duplicating code to add useStoryblokState, which will help linking
 * the page with the visual editor that Storyblok provides
 *
 * @param Component The page component
 * @returns Same page but augmented with storyblok configurations
 */
export const storyblokPage = (Component) => {
  return ({ story, ...props }) => {
    // useStoryblokState is used for linking the component with
    story = useStoryblokState(story, { preventClicks: true, resolveRelations: relations });
    return <Component story={story} {...props} />;
  };
};

/**
 * Helper function to return props for a statically ganerated page using storyblok
 *
 * @param slug Slug of the page (story to fetch)
 * @param ctx Page context
 * @returns Set of props for the page
 */
export const storyBlokStaticProps = (slug) => async (ctx) => {
  const { preview = false } = ctx;

  // load the published content outside of the preview mode
  const sbParams: ISbStoryParams = {
    version: preview ? 'draft' : 'published',
    resolve_relations: relations,
    cv: Date.now(),
  };

  const api = getStoryblokApi();
  // console.log('calling storyblok with params', slug, sbParams);
  const { data } = await api.get(`cdn/stories/${slug}`, sbParams);
  // console.log('storyblok data', JSON.stringify(data, null, 2));

  return {
    props: {
      story: data ? data.story : null,
      key: data ? data.story.id : false,
      preview,
    },
    revalidate: 3600, // revalidate every hour
  };
};

/**
 * Render a Storyblok's RichText component
 */
export const RichText = ({ content }) => <div dangerouslySetInnerHTML={{ __html: renderRichText(content) }}></div>;
