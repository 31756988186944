import Link from 'next/link';

export const LinkIf = ({ children, url, className = undefined }) =>
  url ? (
    <Link href={url}>
      <a className={className}>{children}</a>
    </Link>
  ) : (
    children
  );

export default LinkIf;
