import { Modal } from '@objectedge/pecai-storefront-ds';
import { SideFilter } from '../SideFilter';
import styles from './FilterModal.module.scss';

type FilterModalProps = {
  isOpen: boolean;
  cancel: (...args: any[]) => any;
};

export const FilterModal = ({ isOpen, cancel }: FilterModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      title="Filtrar"
      cancelHandler={cancel}
      showOkButton={true}
      okHandler={cancel}
      okButtonLabel="FILTRAR"
      showCancelButton={false}
      size="lg"
      className={styles['main']}
      okButtonProps={{ style: { flex: 1 } }}
    >
      <SideFilter isModal/>
    </Modal>
  );
};

export default FilterModal;
