import { ImageProp, StoryBlokEditable } from '~/utils/prop-types';
import styles from './AdvantageCard.module.scss';
import { Typography } from '@objectedge/pecai-storefront-ds';

export interface AdvantageCardProps extends StoryBlokEditable {
  title: string;
  subtitle: string;
  icon: ImageProp;
}

export const AdvantageCard = ({ title, subtitle, icon, storyEditable }: AdvantageCardProps) => {
  return (
    <div {...storyEditable} className={styles.advantageCard}>
      <div className={styles.iconContainer}>
        <img src={icon.filename} />
      </div>
      <div className={styles.textContainer}>
        <Typography variant="caption">{title}</Typography>
        <Typography variant="body-small">{subtitle}</Typography>
      </div>
    </div>
  );
};

export default AdvantageCard;
