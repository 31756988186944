import React, { useEffect, useState } from 'react';

export const ImageViewer = ({ src, fallbackSrc, ...others }) => {
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    if (src) {
      setImgSrc(src);
    }
  }, [src]);

  const onError = () => {
    setImgSrc(fallbackSrc);
  };

  return <img src={imgSrc} onError={onError} {...others} />;
};

export default ImageViewer;
