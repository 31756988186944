import { useForm, Controller } from 'react-hook-form';
import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import styles from './SearchByPart.module.scss';
import { Input, Button, Color } from '@objectedge/pecai-storefront-ds/src/components';
import Yup from '~/utils/yup';
import { yupResolver } from '@hookform/resolvers';
import { CatalogKindOfSearch } from '~/operations';
import { selectedPart } from '~/state/customer';
import GA from '~/utils/ga';

export interface SearchByPartProps {
  onSearch?: (any?) => any;
}

const schema = Yup.object().shape({
  part: Yup.string().required(),
});

const SearchByPart = ({ onSearch = () => {} }: SearchByPartProps) => {
  const router = useRouter();
  const { control, errors, handleSubmit, setValue } = useForm({
    defaultValues: {
      part: selectedPart(),
    },
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });

  useEffect(() => {
    if (router.isReady) {
      if (router.pathname === '/busca' && !router.query.searchValue) {
        setValue('part', '');
      } else if (router.pathname === '/busca' && router.query.searchValue) {
        setValue('part', router.query.searchValue);
      }
    }
  }, [router.isReady]);

  const impression = useCallback((searchText) => {
    try {
      GA.searchProduct(searchText);
    } catch (e) {}
  }, []);

  const search = (values) => {
    selectedPart(values?.part);
    impression(values?.part);
    onSearch({ type: CatalogKindOfSearch.Product, value: values?.part });
  };

  return (
    <form className={styles['main']} onSubmit={handleSubmit(search)}>
      <Controller
        control={control}
        as={Input}
        label="Código ou nome da peça"
        name="part"
        status={errors.part ? 'danger' : 'default'}
        helperText={errors.part ? 'Campo obrigatório' : ''}
        className={styles['main__input']}
      />

      <Button id="vehicleFindServices" color={Color.PRIMARY} block className={styles['main__button']} type="submit">
        Nova Busca
      </Button>
    </form>
  );
};

export { SearchByPart };
