import { Chip } from '@material-ui/core';
import clsx from 'clsx';


interface FiltersInterface {
  id: string;
  name: string;
  type: string;
}

interface ChipFilterInterface {
  isModal?: boolean;
  filters: FiltersInterface[];
  handleFilter: (id: string, name: string, type: string, isChecked: boolean) => void;
};

const ChipFilter = ({ isModal, filters, handleFilter }: ChipFilterInterface) => {
  return (
    <div className={clsx('my-2', isModal && 'ml-3')}>
      {filters.map((filter) => (
        <Chip
          key={filter?.id}
          label={filter?.name}
          className="mr-2 mb-2"
          onDelete={() => {
            handleFilter(filter?.id, filter?.name, filter?.type, false);
          }}
        />
      ))}
    </div>
  );
};

export { ChipFilter };
