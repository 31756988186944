import Link from 'next/link';
import { Container, Typography } from '@objectedge/pecai-storefront-ds';
import { CategoryTree, Maybe } from '~/operations';
import styles from './CategoryListHeader.module.scss';
import { MouseEventHandler } from 'react';
import { useNavigation, Page } from '~/utils/navigation';

interface CategoryItemProps {
  icon?: Maybe<string>;
  id?: Maybe<string>;
  name?: Maybe<string>;
  url_path?: Maybe<string>;
}

const CategoryItem = (props: CategoryItemProps) => {
  const { id, name, url_path } = props;
  const { linkToPage } = useNavigation();
  return (
    <div key={id} className={styles['category-item']}>
      <Link href={linkToPage(Page.category, { id: url_path })}>
        <a>
          <Typography weight="bold">{name}</Typography>
        </a>
      </Link>
    </div>
  );
};

interface CategoryListProps {
  categoryList: Array<Maybe<CategoryTree>>;
  onServiceMouseEnter: MouseEventHandler<HTMLDivElement>;
  onServiceMouseLeave?: MouseEventHandler<HTMLDivElement>;
}

export const CategoryListHeader = ({
  categoryList = [],
  onServiceMouseEnter,
  onServiceMouseLeave,
}: CategoryListProps) => {
  return (
    <div
      className={styles['header-categories']}
      id="service-category-header"
      onMouseEnter={onServiceMouseEnter}
      onMouseLeave={onServiceMouseLeave}
    >
      <Container className={styles['header-categories__container']}>
        {categoryList.map((item) => (
          <CategoryItem key={item?.name} id={item?.name} name={item?.name} url_path={item?.url_key} />
        ))}
      </Container>
    </div>
  );
};

export default CategoryListHeader;
