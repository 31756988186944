import { useEffect, useState } from 'react';
import { Checkbox, Typography } from '@objectedge/pecai-storefront-ds';
import styles from './ListItem.module.scss';
import { useRouter } from 'next/router';

type ListItemProps = {
  id: string;
  name: string;
  type: string;
  handleFilter: (id: string, name: string, type: string, isChecked: boolean) => void;
};

const ListItem = ({ id, name, type, handleFilter }: ListItemProps) => {
  const [isChecked, setIsChecked] = useState(false);
  const router = useRouter();
  const { groupProducts, systems, brands } = router.query;

  useEffect(() => {
    if (groupProducts === id || systems === id || brands === id) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  });

  const handleFilterItemChange = () => {
    setIsChecked(!isChecked);
    handleFilter(id, name, type, !isChecked);
  };

  return (
    <li key={id} className={styles['list-item']}>
      <Typography variant="body-small">
        <Checkbox id={id} checked={isChecked} label={name} onChange={handleFilterItemChange} />
      </Typography>
    </li>
  );
};

export { ListItem };
