import { useReactiveVar } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers';
import {
  PartsIconGray,
  PartsIconWhite,
  PlateIconGray,
  PlateIconWhite,
  Typography,
} from '@objectedge/pecai-storefront-ds';
import Router from 'next/router';
import { useCallback, useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { CatalogKindOfSearch, VehicleDetails } from '~/operations';
import { selectedCarPlateState, selectedVehicleState } from '~/state/customer';
import { RichText } from '~/utils/storyblok';
import { getVehicleName } from '~/utils/vehicle';
import Yup from '~/utils/yup';
import { PreFilledVersionForm, VehicleSelector } from '../forms/SearchByVehicleOrPlate/subcomponents';
import { SearchByPartsName } from './SearchByPartsName';
import SearchByPlate from './SearchByPlate';
import styles from './SearchPartsBanner.module.scss';
import { AuthenticationContext } from '../context/Authentication';
import { useLoginAndRegisterModal } from '~/utils/useLoginAndRegisterModal';

export interface SearchPartsBannerProps {
  title?: string;
}

export const SearchPartsBanner: React.FC<SearchPartsBannerProps> = ({ title }) => {
  const savedCarPlate = useReactiveVar(selectedCarPlateState);
  const { user } = useContext(AuthenticationContext);
  const { handleGoToLoginModal } = useLoginAndRegisterModal();
  const savedSelectedVehicle = useReactiveVar(selectedVehicleState);
  const [plateSearch, setPlateSearch] = useState<boolean>(true);
  const [advancedSearch, setAdvancedSearch] = useState<boolean>(false);
  const [openPrefilledModal, setOpenPrefilledModal] = useState<boolean>(false);
  const [versionSelectorVehicles, setVersionSelectorVehicles] = useState<VehicleDetails | undefined>({});
  const [vehicleValue, setVehicleValue] = useState(selectedVehicleState);

  const schema = Yup.object().shape({
    vehicle: Yup.string().required(),
  });

  const { setValue } = useForm({
    defaultValues: {
      vehiclePlate: savedCarPlate ? savedCarPlate?.plate : '',
      vehicle: savedSelectedVehicle ? getVehicleName(savedSelectedVehicle) : '',
    } as Record<string, any>,
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });

  useEffect(() => {
    selectedVehicleState('');
    selectedCarPlateState(null);
  }, []);

  const checkLoggedUser = () => {
    if (!user) {
      handleGoToLoginModal();
      return;
    }
    setAdvancedSearch(true);
  };

  const onSearch = useCallback((searchParameters) => {
    if (searchParameters.type === CatalogKindOfSearch.Plate) {
      Router.push(
        `/busca?searchType=${searchParameters?.type}&plate=${searchParameters?.value?.name}&model=${searchParameters?.value?.model}&engine=${searchParameters?.value?.engine}`
      );
    } else if (searchParameters.type === CatalogKindOfSearch.VehicleDetail) {
      Router.push(
        `/busca?searchType=${searchParameters?.type}&make=${searchParameters?.value?.make}&model=${searchParameters?.value?.model}&version=${searchParameters?.value?.version}&year=${searchParameters?.value?.year}`
      );
    } else {
      Router.push(`/busca?searchType=${searchParameters?.type}&searchValue=${searchParameters?.value}`);
    }
  }, []);

  const search = (vehicle) => {
    if (vehicle?.make) {
      onSearch && onSearch({ type: CatalogKindOfSearch.VehicleDetail, value: vehicle });
    } else {
      onSearch && onSearch({ type: CatalogKindOfSearch.VehicleDetail, value: vehicleValue });
    }
  };

  const handleSetPlateSearch = () => {
    setPlateSearch(true);
  };

  const handleSetPartsSearch = () => {
    setPlateSearch(false);
  };

  const selectVehicle = async (vehicle) => {
    setValue('vehicle', getVehicleName(vehicle));
    setVehicleValue(vehicle);
    await search(vehicle);
  };

  const selectVehicleByPLate = async (vehicle) => {
    setVehicleValue('');
    await search(vehicle);
    setVersionSelectorVehicles({});
  };

  if (
    openPrefilledModal &&
    Object.keys(versionSelectorVehicles || {}).length &&
    versionSelectorVehicles?.models?.length
  ) {
    return (
      // Version selector that uses the response from vehicle plate search
      <PreFilledVersionForm
        vehicles={versionSelectorVehicles}
        back={() => {
          setValue('carPlate', '');
          setOpenPrefilledModal(false);
        }}
        selectVehicle={selectVehicleByPLate}
      />
    );
  }

  return (
    <div className={styles.container}>
      {title && (
        <div className={styles.heroTitle}>
          <Typography variant="h2" color="primary-900" weight="bold">
            <RichText content={title} />
          </Typography>
        </div>
      )}
      <div className={styles.searchFrame}>
        <div className={styles.searchChoiceContainer}>
          <div
            className={plateSearch ? styles.plateChoiceSelected : styles.plateChoice}
            onClick={() => handleSetPlateSearch()}
          >
            {plateSearch ? (
              <PlateIconWhite size={32} className={styles.partIcon} />
            ) : (
              <PlateIconGray size={32} className={styles.partIcon} />
            )}
            <Typography variant="body-text-1" color={plateSearch ? 'light' : 'secondary-100'} weight="normal">
              Busca por placa
            </Typography>
          </div>
          <div
            className={!plateSearch ? styles.partsChoiceSelected : styles.partsChoice}
            onClick={() => handleSetPartsSearch()}
          >
            {!plateSearch ? (
              <PartsIconWhite size={32} className={styles.plateIcon} />
            ) : (
              <PartsIconGray size={32} className={styles.plateIcon} />
            )}
            <Typography variant="body-text-1" color={!plateSearch ? 'light' : 'secondary-100'} weight="normal">
              Busca por peça
            </Typography>
          </div>
        </div>
        <div className={styles.searchInputContainer}>
          {!advancedSearch ? (
            <>
              {plateSearch ? <SearchByPlate onSearch={onSearch} /> : <SearchByPartsName onSearch={onSearch} />}
              <div className={styles.advancedSearch} onClick={() => checkLoggedUser()}>
                <Typography variant="body-regular" weight="normal">
                  Busca avançada
                </Typography>
              </div>
            </>
          ) : (
            <div className={styles.selectVehicleContainer}>
              <VehicleSelector back={() => setAdvancedSearch(false)} select={selectVehicle} />
            </div>
          )}
        </div>

        {/* This is the bottom section of this search component. For now we
        don't have the functionality working, but the layout is ready */}

        {/* <div className={styles.bottomContainer}>
          <div className={styles.textAboveCards}>
            <Typography variant="body-text-1" color="secondary-500" weight="light">
              Últimas buscas
            </Typography>
            {!isMobileView && (
              <Link href="">
                <a>
                  <Typography variant="body-text-1" weight="light">
                    Ver mais buscas
                  </Typography>
                </a>
              </Link>
            )}
          </div>
          <div className={styles.cardsContainer}>
            <div className={styles.cardInfo}>
              <div className={styles.upperDiv}>
                <Typography variant="body-small" color="secondary-300" weight="light" className={styles.carName}>
                  Toyota Etios CS 1.5 2016
                </Typography>
                <CloseRoundedSquare size={16} className={styles.closeIcon} onClick={() => handleCloseCard()} />
              </div>
              <div className={styles.bottomDiv}>
                <Typography variant="body-small" color="secondary-300" weight="light" className={styles.plateCode}>
                  Placa: PDG-1431
                </Typography>
              </div>
            </div>

            <div className={styles.cardInfo}>
              <div className={styles.upperDiv}>
                <Typography variant="body-small" color="secondary-300" weight="light" className={styles.carName}>
                  Volkswagen Polo 1.0 TSE 2020
                </Typography>
                <CloseRoundedSquare size={16} className={styles.closeIcon} onClick={() => handleCloseCard()} />
              </div>
              <div className={styles.bottomDiv}>
                <Typography variant="body-small" color="secondary-300" weight="light" className={styles.plateCode}>
                  Placa: PHD-1E49
                </Typography>
              </div>
            </div>
          </div>
          {isMobileView && (
            <div className={styles.mobileAdvSearch}>
              <Link href="">
                <a>
                  <Typography variant="body-text-1" weight="light">
                    Ver mais buscas
                  </Typography>
                </a>
              </Link>
            </div>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default SearchPartsBanner;
