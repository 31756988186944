import {
  Container,
  ConfirmationIcon,
  CreditCardIcon,
  DeliveryIcon,
  ProfileIcon,
  Typography,
  Row,
  Col,
  CheckIcon,
} from '@objectedge/pecai-storefront-ds';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import styles from './Stepper.module.scss';
import { Page, useNavigation } from '~/utils/navigation';



const Step = ({ id, children, current, Icon, onClickEvent = () => {} }) => (
  <Col className={clsx(current === id && styles.active, current > id && styles.done)} onClick={onClickEvent}>
    {current > id ? <CheckIcon size={24} /> : <Icon size={24} color="white" />}
    <Typography variant="body-text-1" mobileClass="caption-2">
      {children}
    </Typography>
  </Col>
);

export const Stepper = () => {
  const router = useRouter();
  const { goToPage } = useNavigation();

  let step = 0;
  if (router.pathname === '/login' && router.query?.back === '/checkout/pagamento') step = 1;
  if (router.pathname === '/cadastro' && router.query?.back === '/checkout') step = 1;
  if (router.pathname === '/checkout/entrega') step = 2;
  if (router.pathname === '/checkout/pagamento') step = 3;
  if (router.pathname === '/checkout/confirmacao') step = 4;

  if (!step) return null;
  return (
    <Container fluid className={`p-0`}>
      <Row noGutters className={styles.stepper}>
        <Step id={1} current={step} Icon={ProfileIcon} onClickEvent={()=>{goToPage(Page.cart)}}>
          Carrinho
        </Step>
        <Step id={2} current={step} Icon={DeliveryIcon} onClickEvent={()=>{goToPage(Page.checkoutEntrega)}}>
          Entrega
        </Step>
        <Step id={3} current={step} Icon={CreditCardIcon} onClickEvent={()=>{goToPage(Page.checkoutPayment)}}>
          Pagamento
        </Step>
        <Step id={4} current={step} Icon={ConfirmationIcon}>
          Confirmação
        </Step>
      </Row>
    </Container>
  );
};

export default Stepper;
