import { ProductCard as DSProductCard } from '@objectedge/pecai-storefront-ds';

export const ProductCard = (props) => (
  <DSProductCard
    upperTag={props.upperTag}
    image={props.image.filename}
    name={props.name}
    description={props.description.split('\n')}
    listPrice={props.listPrice}
    price={props.salePrice}
    priceTag={props.priceTag}
    // addToCartFunction={() => handleAddToCart(product.productId)}
  />
);

export default ProductCard;
