import { useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers';
import Loader from '~/components/common/Loader';
import { Button, Input, TextfieldCommonMasks, Typography } from '@objectedge/pecai-storefront-ds';
import { useEffect, useState, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAlerts } from '~/utils/useAlerts';
import { selectedVehicleState, selectedCarPlateState } from '~/state/customer';
import { getVehicleName } from '~/utils/vehicle';
import Yup from '~/utils/yup';
import { VehicleSelector, PreFilledVersionForm } from '../forms/SearchByVehicleOrPlate/subcomponents';
import styles from './SearchByPlate.module.scss';
import { getReCaptchaToken, ReCaptchaActions } from '~/utils/reCaptcha';
import { vehicleSearchDefaultErrorMessage, vehicleSearchRecaptchaErrorMessage } from '~/utils/vehicleSearchByPlate';
import { CatalogKindOfSearch, useGetVehicleDetailsByPlateLazyQuery, VehicleDetails } from '~/operations';
import GA from '~/utils/ga';
import { AuthenticationContext } from '../context/Authentication';
import { useLoginAndRegisterModal } from '~/utils/useLoginAndRegisterModal';

export interface GarageSelectorProps {
  onSearch?: (any?) => any;
}

const schema = Yup.object().shape({
  vehicle: Yup.string().required(),
});

export const SearchByPlate = ({ onSearch }: GarageSelectorProps) => {
  const savedCarPlate = useReactiveVar(selectedCarPlateState);
  const savedSelectedVehicle = useReactiveVar(selectedVehicleState);
  const { user } = useContext(AuthenticationContext);
  const { handleGoToLoginModal } = useLoginAndRegisterModal();
  const { actions } = useAlerts();
  const [carPlateValue, setCarPlateValue] = useState(savedCarPlate ? savedCarPlate?.plate : '');
  const [vehicleValue, setVehicleValue] = useState(selectedVehicleState);

  const [vehicleSelector, showVehicleSelector] = useState(false);
  const [versionSelectorVehicles, setVersionSelectorVehicles] = useState<VehicleDetails | undefined>({});
  const [loadingCarPlate, setLoadingCarPlate] = useState(false);
  const [carPlateErrorMessage, setCarPlateErrorMessage] = useState('');
  const [openPrefilledModal, setOpenPrefilledModal] = useState(false);

  const { control, reset, handleSubmit, setValue, clearErrors } = useForm({
    defaultValues: {
      vehiclePlate: savedCarPlate ? savedCarPlate?.plate : '',
      vehicle: savedSelectedVehicle ? getVehicleName(savedSelectedVehicle) : '',
    } as Record<string, any>,
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });

  const [fetchVehiclePlate, { data, loading }] = useGetVehicleDetailsByPlateLazyQuery();

  const impression = useCallback((searchText) => {
    try {
      GA.searchCar(searchText);
    } catch (e) {}
  }, []);

  const search = (vehicle) => {
    if (vehicle?.make) {
      onSearch && onSearch({ type: CatalogKindOfSearch.VehicleDetail, value: vehicle });
    } else if (vehicleValue?.make) {
      onSearch && onSearch({ type: CatalogKindOfSearch.VehicleDetail, value: vehicleValue });
    } else {
      onSearch && onSearch({ type: CatalogKindOfSearch.Plate, value: { name: carPlateValue, ...vehicle } });
    }
  };

  const selectVehicle = async (vehicle) => {
    setValue('vehicle', getVehicleName(vehicle));
    setVehicleValue(vehicle);
    setCarPlateValue('');
    await search(vehicle);
    showVehicleSelector(false);
  };

  const selectVehicleByPLate = async (vehicle) => {
    setVehicleValue('');
    await search(vehicle);
    setVersionSelectorVehicles({});
  };

  useEffect(() => {
    setVersionSelectorVehicles(data?.vehicleDetailsByPlate?.edge || {});
  }, [data]);

  const handleSearchVehicleByPlate = async (plate: string) => {
    impression(plate);
    setLoadingCarPlate(true);
    setCarPlateValue(plate);
    try {
      setCarPlateErrorMessage('');
      const reCaptchaToken = await getReCaptchaToken(ReCaptchaActions.vehicleSearchByPlate);
      if (!!reCaptchaToken) {
        fetchVehiclePlate({
          variables: { plate },
          context: { captcha: reCaptchaToken },
        });
        setOpenPrefilledModal(true);
        setVehicleValue('');
        setValue('vehicle', '');
        setLoadingCarPlate(false);
        // If vehicleSearch returns only 1 car, select it automatically
      } else {
        setLoadingCarPlate(false);
        setCarPlateErrorMessage(vehicleSearchRecaptchaErrorMessage);
      }
    } catch (e) {
      setLoadingCarPlate(false);
      if (e instanceof Error && e.message === 'Too many requests') {
        setCarPlateErrorMessage(vehicleSearchRecaptchaErrorMessage);
      } else {
        setCarPlateErrorMessage(vehicleSearchDefaultErrorMessage);
      }
    }
  };

  useEffect(() => {
    // Update values in case we change in another place (like the header)
    setValue('carPlate', savedCarPlate ? savedCarPlate?.plate : '');
    setCarPlateValue(savedCarPlate ? savedCarPlate?.plate : '');
  }, [savedCarPlate]);

  useEffect(() => {
    if (versionSelectorVehicles) {
      if (Object.keys(versionSelectorVehicles).length) {
        if (!versionSelectorVehicles?.models?.length) {
          setValue('carPlate', '');
          actions.addDangerAlert(
            'Infelizmente, não encontramos uma referência para a placa pesquisada. Digite novamente ou realize uma nova busca nos outros campos de pesquisa!'
          );
        }
      }
    }
  }, [versionSelectorVehicles]);

  if (vehicleSelector) {
    return <VehicleSelector back={() => showVehicleSelector(false)} select={selectVehicle} />;
  }
  if (
    openPrefilledModal &&
    Object.keys(versionSelectorVehicles || {}).length &&
    versionSelectorVehicles?.models?.length
  ) {
    return (
      // Version selector that uses the response from vehicle plate search
      <PreFilledVersionForm
        vehicles={versionSelectorVehicles}
        back={() => {
          setValue('carPlate', '');
          setCarPlateValue('');
          setOpenPrefilledModal(false);
        }}
        selectVehicle={selectVehicleByPLate}
      />
    );
  }

  return (
    <div className={styles.searchIsolatedContainer}>
      <Typography variant="h5" weight="bold" color="primary-900">
        Número da Placa
      </Typography>

      <form onSubmit={handleSubmit(search)}>
        <Controller
          control={control}
          name="carPlate"
          render={(props) => (
            <>
              <Input
                {...props}
                className={styles.searchInput}
                mask={TextfieldCommonMasks.carPlate}
                label="ABC1234"
                status={carPlateErrorMessage ? 'danger' : 'default'}
                helperText={carPlateErrorMessage ?? 'Usaremos a placa para encontrar seu veículo.'}
                onChange={(e) => {
                  if (!user) {
                    handleGoToLoginModal();
                    return;
                  }
                  props.onChange(e);
                  clearErrors('vehicle');
                  if (e.target.value.match(/[a-zA-Z]{3}[0-9][0-9a-zA-Z][0-9][0-9]/)) {
                    handleSearchVehicleByPlate(e.target.value);
                  }
                }}
              />
              <div className={styles.buttonContainer}>
                <Button
                  className={styles.clearInputButton}
                  outline
                  color="secondary"
                  onClick={() =>
                    reset({
                      carPlate: '',
                    })
                  }
                >
                  <Typography variant="body-regular" weight="bold">
                    Limpar
                  </Typography>
                </Button>
                <Button className={styles.ctaSearchButton} type="submit">
                  <Typography variant="body-regular" weight="bold">
                    Procurar
                  </Typography>
                </Button>
              </div>
            </>
          )}
        />

        {(loadingCarPlate || loading) && (
          <div className={styles.loaderContainer}>
            <Loader constrained />
          </div>
        )}
      </form>
    </div>
  );
};

export default SearchByPlate;
