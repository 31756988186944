import { useContext, useMemo } from 'react';
import { FilterContext } from '~/components/context/FilterContext';
import { SearchContext } from '~/components/context/SearchContext';
import { ChipFilter } from '../ChipFilter';
import { DropdownFilter } from './DropdownFilter';

import styles from './SideFilter.module.scss';

type SideFilterProps = {
  isModal?: boolean;
};

const SideFilter = ({ isModal }: SideFilterProps) => {
  const { searchData } = useContext(SearchContext);
  const { selectedFilters, handleFilter } = useContext(FilterContext);
  const reverseFilters = {
    brands: searchData?.filters?.brands,
    systems: searchData?.filters?.systems,
    groupsProducts: searchData?.filters?.groupsProducts,
  };
  const categoriesMap = searchData?.filters ? Object.entries(reverseFilters) : null;

  const sortedCategories = useMemo(() => {
    const sort: any[] = [];

    categoriesMap?.map(({ '0': categoryName, '1': categoryItems }, index) => {
      if (categoryName == 'groupsProducts') {
        categoryName = 'Linhas de produtos';
      } else if (categoryName == 'systems') {
        categoryName = 'Sistemas';
      } else if (categoryName == 'brands') {
        categoryName = 'Marcas';
      }

      if (Array.isArray(categoryItems) && categoryItems.length > 0) {
        const sorted = [...categoryItems];

        sorted?.sort((a, b) =>
          a.filter.name?.toLowerCase() !== b.filter.name?.toLowerCase()
            ? a.filter.name?.toLowerCase() < b.filter.name?.toLowerCase()
              ? -1
              : 1
            : 0
        );
        sort.push({ '0': categoryName, '1': sorted });
      }
    });
    return sort;
  }, [categoriesMap]);

  return (
    <>
      {searchData?.products?.edges.length > 0 && (
        <>
          {selectedFilters && <ChipFilter isModal={isModal} handleFilter={handleFilter} filters={selectedFilters} />}
          <div className={styles['main']}>
            {sortedCategories?.map(({ '0': categoryName, '1': categoryItems }) => {
              return (
                <DropdownFilter
                  categoryName={categoryName}
                  categoryItems={categoryItems}
                  key={categoryName}
                  handleFilter={handleFilter}
                />
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export { SideFilter };
