/**
 * Sends an action to Google reCaptcha and returns the token in a promise
 *
 * @param action string The user action to send to reCaptcha. ex: 'VEHICLE_SEARCH_BY_PLATE"
 *
 * @returns Promise
 */
export const getReCaptchaToken: (action: string) => Promise<String> = (action) => {
  return new Promise((resolve, reject) => {
    try {
      window?.grecaptcha?.enterprise?.ready(() => {
        window?.grecaptcha?.enterprise?.execute(process.env.RECAPTCHA_KEY, { action }).then((token) => {
          resolve(token);
        });
      });
    } catch (e) {
      reject(e);
    }
  });
};

export const ReCaptchaActions = {
  vehicleSearchByPlate: 'VEHICLE_SEARCH_BY_PLATE',
};
