import { SbBlokData, storyblokEditable } from '@storyblok/react';
import * as Components from '~/components/bloks';

export interface DynamicComponent {
  blok: SbBlokData;
  noWrapper?: boolean;
}

export const DynamicComponent = ({ blok, noWrapper = false }: DynamicComponent) => {
  if (!blok) return null;

  const Component = Components[blok.component as string];

  if (!Component) {
    return (
      <p>
        The component <strong>{blok.component}</strong> is not a storyblok component.
      </p>
    );
  }

  const editable = storyblokEditable(blok);

  return noWrapper ? (
    <Component {...blok} storyEditable={editable} />
  ) : (
    <span {...editable}>
      <Component {...blok} />
    </span>
  );
};

export const StoryBloks = ({ bloks }) =>
  bloks?.length ? bloks.map((blok) => <DynamicComponent blok={blok} key={blok._uid} />) : null;

export default StoryBloks;
