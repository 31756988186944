import { useRouter } from 'next/router';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SearchContext } from './SearchContext';

interface FilterInterface {
  selectedFilters: any[];
  handleFilter: (id: string, name: string, type: string, isChecked: boolean) => void;
}

export const FilterContext = createContext<FilterInterface>({} as any);

interface FilterProps {
  children: ReactNode;
}

export const Filter = ({ children }: FilterProps) => {
  const { applySearch, searchData } = useContext(SearchContext);
  const router = useRouter();
  const { groupProducts, systems, brands } = router.query;
  const [selectedFilters, setSelectedFilters] = useState<any[]>([]);

  useEffect(() => {
    if (router.isReady && searchData) {
      let routerFilters: any[] = [];
      if (groupProducts) {
        routerFilters.push({
          id: groupProducts,
          name: searchData?.filters.groupsProducts?.find((item) => groupProducts === item.filter?.id)?.filter?.name,
          type: 'GROUP_PRODUCT',
        });
      }
      if (systems) {
        routerFilters.push({
          id: systems,
          name: searchData?.filters.systems?.find((item) => systems === item.filter?.id)?.filter?.name,
          type: 'SYSTEM',
        });
      }
      if (brands) {
        routerFilters.push({
          id: brands,
          name: searchData?.filters.brands?.find((item) => brands === item.filter?.id)?.filter?.name,
          type: 'BRAND',
        });
      }
      setSelectedFilters(routerFilters);
    }
  }, [router.isReady, router.query, searchData]);

  const handleFilter = useCallback(
    (id: string, name: string, type: string, isChecked: boolean) => {
      const filterParam = { id, name, type };
      if (isChecked) {
        setSelectedFilters((prevState) => [...prevState, filterParam]);
        applySearch(0, [...selectedFilters, filterParam]);
      } else {
        var item = selectedFilters.find((x) => x.id == filterParam.id);
        const index = item ? selectedFilters.indexOf(item) : -1;
        selectedFilters.splice(index, 1);
        setSelectedFilters([...selectedFilters]);
        applySearch(0, [...selectedFilters]);
      }
    },
    [selectedFilters, router.isReady]
  );

  return <FilterContext.Provider value={{ selectedFilters, handleFilter }}>{children}</FilterContext.Provider>;
};
