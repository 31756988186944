import styles from './Cart.module.scss'
;
const Cart = () => {
    return(
        <div
            className={styles['main']}
        >
            carrinho
        </div>
    )
}

export { Cart };