import { ArrowLeftCircleIcon, Button, Color, Select, Typography } from '@objectedge/pecai-storefront-ds';
import { useEffect, useState } from 'react';
import { useGetVehicleVersionsQuery, useGetVehicleModelYearsQuery } from '~/operations';
import styles from '../SearchByVehicleOrPlate.module.scss';

export const VersionForm = ({ make, model, year, selectVersion, selectYear, back, selectCar }) => {
  const { data: yearData } = useGetVehicleModelYearsQuery({
    variables: { modelName: model, makeName: make, useThirdPartyApi: true },
  });
  const { data } = useGetVehicleVersionsQuery({
    variables: { modelName: model, makeName: make, year: parseInt(year), useThirdPartyApi: true },
    skip: !year,
  });

  const [version, setVersion] = useState();

  const versions = (data?.versionsList || []).map((v) => ({ label: `${model} ${v}`, value: v }));
  const years = (yearData?.yearsList || []).map((y) => ({ label: y + '', value: y + '' }));

  return (
    <div className={styles.makeForm}>
      <div className={styles.header}>
        <ArrowLeftCircleIcon size={24} onClick={back} />
        <Typography variant="h3">Escolha o Modelo</Typography>
      </div>
      <div>
        <Select
          id="vehicleYear"
          placeholder=""
          label="Ano*"
          disabled={!yearData}
          options={years}
          onChange={(v: any) => {
            selectYear(v.value);
            setVersion(undefined);
            selectVersion(undefined);
          }}
          isSearchable={false}
        />
        <Select
          id="vehicleVersion"
          placeholder=""
          label="Versão*"
          disabled={!data}
          options={versions}
          onChange={(v: any) => {
            selectVersion(v.value);
            setVersion(v.value);
          }}
          value={version ? { label: version + '', value: version + '' } : undefined}
          isSearchable={false}
        />
      </div>
      <div className="d-flex flex-grow-1">
        <Button
          color={Color.SECONDARY}
          outline
          block
          onClick={selectCar}
          className="align-self-end"
          disabled={!version || !year}
        >
          Selecionar Carro
        </Button>
      </div>
    </div>
  );
};

export default VersionForm;
