import { Button, Typography } from '@objectedge/pecai-storefront-ds';
import styles from './PromoBanner.module.scss';
import Link from 'next/link';
import { useContext } from 'react';
import { AuthenticationContext } from '~/components/context/Authentication';
import { ImageProp, StoryBlokEditable } from '~/utils/prop-types';
import { useLoginAndRegisterModal } from '~/utils/useLoginAndRegisterModal';

export interface PromoBannerProps extends StoryBlokEditable {
  link?: string;
  target: string[];
  title?: string;
  subtitle?: string;
  buttonText?: string;
  backgroundImage: ImageProp;
}

export const PromoBanner = (props: PromoBannerProps) => {
  const { link, target } = props;
  const { provider, isLoggedIn } = useContext(AuthenticationContext);

  const groups = [...(provider?.buyersGroups || []), isLoggedIn ? undefined : 'guest'];

  if (target.length && !target.filter((t) => groups.includes(t)).length) {
    return null;
  }

  return link ? <Link href={link}>{render(props)}</Link> : <>{render(props)}</>;
};

const render = ({ storyEditable, title, subtitle, buttonText, backgroundImage }: PromoBannerProps) => {
  const { handleGoToRegisterModal } = useLoginAndRegisterModal();
  return (
    <a
      onClick={() => (title?.includes('Cadastre-se') ? handleGoToRegisterModal() : '')}
      {...storyEditable}
      className={styles.promoBanner}
      style={{
        backgroundImage: `linear-gradient(90deg, #5a5a5a 41.39%, rgba(0, 0, 0, 0.1) 100%), url(${backgroundImage.filename})`,
        cursor: title?.includes('Cadastre-se') ? 'pointer' : '',
      }}
    >
      <div>
        {title && <Typography variant="h6">{title}</Typography>}
        {subtitle && <Typography variant="body-small">{subtitle}</Typography>}
      </div>
      {buttonText && <Button>{buttonText}</Button>}
    </a>
  );
};

export default PromoBanner;
