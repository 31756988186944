import LinkIf from '~/utils/LinkIf';
import { ImageProp, LinkProp } from '~/utils/prop-types';
import styles from './FeaturedBanner.module.scss';

export interface FeaturedBannerProps {
  imageDesktop: ImageProp;
  imageMobile: ImageProp;
  link: LinkProp;
}

export const FeaturedBanner = ({ imageDesktop, imageMobile, link }: FeaturedBannerProps) => (
  <LinkIf url={link?.url}>
    <a className={styles.featuredBanner}>
      <picture>
        <source media="(min-width: 768px)" srcSet={imageDesktop.filename} />
        <img src={imageMobile.filename} alt="Featured Banner" />
      </picture>
    </a>
  </LinkIf>
);

export default FeaturedBanner;
