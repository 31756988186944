import styles from './SearchBar.module.scss';
import { useForm, Controller } from 'react-hook-form';
import { Input, Button, Color, Typography, SearchIcon } from '@objectedge/pecai-storefront-ds/src/components';
import { selectedPart } from '~/state/customer';
import { useRouter } from 'next/router';
import Yup from '~/utils/yup';
import { yupResolver } from '@hookform/resolvers';
import { useCallback, useEffect, useMemo } from 'react';
import GA from '~/utils/ga';
import { CatalogKindOfSearch } from '~/operations';

interface SearchBarProps {
  onSearch?: (any?) => any;
  helperText?: string;
}

const schema = Yup.object().shape({
  part: Yup.string(),
});

export const SearchBar = ({ onSearch = () => {}, helperText }: SearchBarProps) => {
  const router = useRouter();
  const { control, errors, handleSubmit, setValue } = useForm({
    defaultValues: {
      part: selectedPart(),
    },
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });

  const searchInputLabel = useMemo(() => {
    const { pathname, query } = router;
    if (router.isReady) {
      if (pathname.includes('categoria')) {
        return 'Pesquisar peças dessa categoria';
      }
      if (query.searchType === CatalogKindOfSearch.Plate) {
        return 'Pesquisar peças da sua placa';
      }
      if (query.searchType === CatalogKindOfSearch.VehicleDetail) {
        return 'Pesquisar peças do seu veículo';
      }
      return 'Pesquisar peças';
    }
  }, [router, router.isReady]);

  useEffect(() => {
    if (router.isReady) {
      setValue('part', router.query.searchValue || '');
    }
  }, [router, router.isReady]);

  const impression = useCallback((searchText) => {
    try {
      GA.searchProduct(searchText);
    } catch (e) {}
  }, []);

  const search = (values) => {
    selectedPart(values?.part);
    impression(values?.part);
    onSearch({ value: values?.part });
  };

  return (
    <div>
      <Typography weight="semibold" variant="h6" color="primary-900">
        {searchInputLabel}
      </Typography>
      <form className={styles['main']} onSubmit={handleSubmit(search)}>
        <Controller
          control={control}
          as={<Input icon={<SearchIcon size={24} />} />}
          label="Código ou nome da peça"
          name="part"
          status={errors.part ? 'danger' : 'default'}
          helperText={errors.part ? 'Campo obrigatório' : ''}
          className={styles['main__input']}
        />

        <Button id="vehicleFindServices" color={Color.PRIMARY} block className={styles['main__button']} type="submit">
          Pesquisar
        </Button>
      </form>
      {helperText && (
        <Typography weight="light" variant="body-small" color="secondary-100" className="mt-1 ml-1">
          {helperText}
        </Typography>
      )}
    </div>
  );
};

export default SearchBar;
