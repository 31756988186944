import { Typography } from '~/../pecai-storefront-ds';
import styles from './SubHeader.module.scss';
import { RichTextProp } from '~/utils/prop-types';
import { RichText } from '~/utils/storyblok';

interface SubHeaderProps {
  yellowBandText?: string | RichTextProp;
  darkBandText?: string | RichTextProp;
  showDarkBand?: boolean;
  showYellowBand?: boolean;
}

const SubHeader = ({ yellowBandText, darkBandText, showDarkBand, showYellowBand }: SubHeaderProps) => {
  const renderText = (text: string | RichTextProp) => {
    if (typeof text === 'string') return <>{text}</>;
    return <RichText content={text} />;
  };

  return (
    <div className={styles['sub-header']}>
      {showYellowBand && yellowBandText && (
        <div className={styles['sub-header__yellow']}>
          <Typography variant="body-regular">{renderText(yellowBandText)}</Typography>
        </div>
      )}
      {showDarkBand && darkBandText && (
        <div className={styles['sub-header__dark']}>
          <Typography variant="body-regular">{renderText(darkBandText)}</Typography>
        </div>
      )}
    </div>
  );
};

export default SubHeader;
