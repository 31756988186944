import { StoryBlokEditable } from '~/utils/prop-types';
import FooterDesktop from './FooterDesktop';
import FooterMobile from './FooterMobile';
import WhatsappCta from './WhatsappCta';

export interface FooterProps extends StoryBlokEditable {}

export const Footer = ({ storyEditable }: FooterProps) => {
  return (
    <>
      <WhatsappCta />
      <FooterDesktop className="d-none d-md-block" {...storyEditable} />
      <FooterMobile className="d-md-none" {...storyEditable} />
    </>
  );
};

export default Footer;
