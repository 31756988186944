import { useContext, useEffect, useMemo, useState } from 'react';
import { ArrowUpIcon, ArrowDownIcon, Textfield, Typography } from '@objectedge/pecai-storefront-ds';
import { ListItem } from '../ListItem/ListItem';
import styles from './DropdownFilter.module.scss';
import { SearchContext } from '~/components/context/SearchContext';

interface DropdownFilterProps {
  categoryName: string;
  categoryItems: any;
  handleFilter: (id: string, name: string, type: string, isChecked: boolean) => void;
}

const DropdownFilter = ({ categoryName, categoryItems, handleFilter }: DropdownFilterProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const { searchData } = useContext(SearchContext);
  const [searchTerm, setSearchTerm] = useState('');

  const handleDropdownList = () => {
    setIsOpen(!isOpen);
  };

  const searchResults = useMemo(() => {
    return categoryItems.filter((item) => item?.filter?.name.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [searchTerm, searchData?.filters]);

  const filteredSearchResults = useMemo(() => {
    return searchResults.filter((result) => !!result?.filter?.name);
  }, [searchResults]);

  return (
    <div className={styles['main']}>
      <div className={styles['main__dropdown']} onClick={handleDropdownList}>
        <Typography variant="body-regular" weight="semibold" className={styles['main__dropdown__tittle']}>
          {categoryName}
        </Typography>

        {isOpen ? <ArrowDownIcon size={24} /> : <ArrowUpIcon size={24} />}
      </div>

      <Textfield
        small
        value={searchTerm}
        label="Buscar"
        className="mb-3"
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
      />
      <ul className={`${styles['main__list']} ${isOpen ? styles['main__list__visible'] : ''}`}>
        {filteredSearchResults?.length > 0 ? (
          filteredSearchResults?.map((item) => {
            return (
              <ListItem
                id={item?.filter?.id}
                name={item?.filter?.name}
                type={item?.filter?.type}
                key={item?.filter?.id}
                handleFilter={handleFilter}
              />
            );
          })
        ) : (
          <Typography variant="body-small" weight="bold">
            Nenhum resultado
          </Typography>
        )}
      </ul>
    </div>
  );
};

export { DropdownFilter };
