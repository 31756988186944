/**
 * Get vehicle name like BMW M2 2.0 2025
 */
export const getVehicleName = (vehicle): string =>
  vehicle
    ? `${vehicle.makeName || vehicle.make} ${vehicle.modelName || vehicle.model} ${
        vehicle.versionName || vehicle.version
      } ${vehicle.year}`
    : '';

export const getVehicleNameByPlate = (vehicle): string =>
  vehicle && vehicle?.plate
    ? `${vehicle?.plate} ${vehicle?.make} ${vehicle?.model} ${vehicle?.engine} ${vehicle?.madeYear}`
    : '';
