import styles from './FooterMobile.module.scss';
import { LogoMobile } from '~/components/icons';
import clsx from 'clsx';
import { Typography, Container } from '@objectedge/pecai-storefront-ds';
import ContactSection from './ContactSection';

export interface FooterMobileProps {
  className: string;
}

export const FooterMobile = ({ className }: FooterMobileProps) => {
  return (
    <footer className={clsx(styles.container, className)}>
      <Container className={styles['main']}>
        <div className="d-flex flex-column align-items-center">
          <LogoMobile />
          <ContactSection className="d-flex flex-column align-items-center mt-3" />
          <div className={styles['main__info']}>
            <Typography variant="body-xsmall" className="mb-4">
              Com o Peça Aí, você acha a peça certa para cada carro e compra num só lugar. É o maior estoque digital de
              peças do país!
            </Typography>
            <div id="armored_website">
              <param id="aw_preload" value="true" />
              <param id="aw_use_cdn" value="true" />
            </div>
            <Typography variant="body-xsmall">
              {new Date().getFullYear()} - {'Peça aí'.toUpperCase()} – Todos os direitos reservados
            </Typography>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default FooterMobile;
