import { yupResolver } from '@hookform/resolvers';
import { Button, Input, Typography } from '@objectedge/pecai-storefront-ds';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CatalogKindOfSearch } from '~/operations';
import { selectedPart } from '~/state/customer';
import GA from '~/utils/ga';
import Yup from '~/utils/yup';
import styles from './SearchByPartsName.module.scss';

export interface SearchByPartProps {
  onSearch?: (any?) => any;
}

const schema = Yup.object().shape({
  part: Yup.string().required(),
});

const SearchByPartsName = ({ onSearch = () => {} }: SearchByPartProps) => {
  const router = useRouter();
  const { control, errors, handleSubmit, setValue, reset } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (router.isReady) {
      if (router.pathname === '/busca' && !router.query.searchValue) {
        setValue('part', '');
      } else if (router.pathname === '/busca' && router.query.searchValue) {
        setValue('part', router.query.searchValue);
      }
    }
  }, [router.isReady]);

  const impression = useCallback((searchText) => {
    try {
      GA.searchProduct(searchText);
    } catch (e) {}
  }, []);

  const search = (values) => {
    selectedPart(values?.part);
    impression(values?.part);
    onSearch({ type: CatalogKindOfSearch.Product, value: values?.part });
  };

  return (
    <div className={styles.searchIsolatedContainer}>
      <Typography variant="h5" weight="bold" color="primary-900">
        Código ou nome da peça
      </Typography>

      <form onSubmit={handleSubmit(search)}>
        <Controller
          control={control}
          name="part"
          as={Input}
          className={styles.searchInput}
          label="Digite o código ou a peça"
          status={errors.part ? 'danger' : 'default'}
          helperText={errors.part ? 'Campo obrigatório' : ''}
          style={errors.part && { border: '1px solid #eb5757' }}
        />

        <div className={styles.buttonContainer}>
          <Button
            className={styles.clearInputButton}
            outline
            color="secondary"
            onClick={() =>
              reset({
                part: '',
              })
            }
          >
            <Typography variant="body-regular" weight="bold">
              Limpar
            </Typography>
          </Button>
          <Button className={styles.ctaSearchButton} type="submit">
            <Typography variant="body-regular" weight="bold">
              Procurar
            </Typography>
          </Button>
        </div>
      </form>
    </div>
  );
};

export { SearchByPartsName };
