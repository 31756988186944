import styles from './FooterDesktop.module.scss';
import { Logo } from '~/components/icons';
import clsx from 'clsx';
import Link from 'next/link';
import { Typography, Container } from '@objectedge/pecai-storefront-ds';
import ContactSection from './ContactSection';
import { Page } from '~/utils/navigation';
import { useLoginAndRegisterModal } from '~/utils/useLoginAndRegisterModal';

export interface FooterDesktopProps {
  className: string;
}

export const FooterDesktop = ({ className }: FooterDesktopProps) => {
  const { handleGoToLoginModal, handleGoToRegisterModal } = useLoginAndRegisterModal();
  return (
    <footer className={clsx(styles.container, className)}>
      <Container className={styles['main']}>
        <div className={styles['main__content']}>
          <div>
            <Logo />
            <Typography variant="body-xsmall" className="mt-3 mb-4">
              Com o Peça Aí, você acha a peça certa para cada carro e compra num só lugar. É o maior estoque digital de
              peças do país!
            </Typography>
            <div id="armored_website">
              <param id="aw_preload" value="true" />
              <param id="aw_use_cdn" value="true" />
            </div>
            <Typography variant="body-xsmall">
              {new Date().getFullYear()} - {'Peça aí'.toUpperCase()} – Todos os direitos reservados
            </Typography>
          </div>
          <div className={styles['main__section']}>
            <div>
              <Typography variant="h6" className="mb-3">
                Peça aí
              </Typography>
              <Link href={`${process.env.PECAAI_LP_URL}`}>
                <a>
                  <Typography variant="body-xsmall">Sobre</Typography>
                </a>
              </Link>
              <Link href={Page.privacyTerms}>
                <a>
                  <Typography className="mt-2" variant="body-xsmall">Política de Privacidade</Typography>
                </a>
              </Link>
              <a onClick={() => handleGoToRegisterModal()}>
                <Typography className="my-2" variant="body-xsmall">
                  Cadastre-se
                </Typography>
              </a>
              <a onClick={() => handleGoToLoginModal()}>
                <Typography variant="body-xsmall">Entrar</Typography>
              </a>
            </div>
            <ContactSection />
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default FooterDesktop;
