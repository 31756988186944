import { Typography } from '@objectedge/pecai-storefront-ds';
import { FastDeliveryIcon, BestPriceIcon, Star3Icon } from '~/components/icons';
import styles from './Features.module.scss';

const Features = () => {
  return (
    <div className={styles['main']}>
      <div className={styles['main__wrapper']}>
        <div className={styles['main__row']}>
          <FastDeliveryIcon />
          <div className={styles['main__text-container']}>
            <Typography variant="h3" className={styles['main__title']} weight="bold">
              Entrega Rápida
            </Typography>
            <Typography variant="body-text-2" className={styles['main__subtitle']}>
              Receba a peça que você precisa em até 2h em sua oficina.
            </Typography>
          </div>
        </div>
        <div className={styles['main__row']}>
          <BestPriceIcon />
          <div className={styles['main__text-container']}>
            <Typography variant="h3" className={styles['main__title']} weight="bold">
              Melhores Preços
            </Typography>
            <Typography variant="body-text-2" className={styles['main__subtitle']}>
              Compre e economize! Melhor média de preços do mercado.
            </Typography>
          </div>
        </div>
        <div className={styles['main__row']}>
          <div className={styles['main__circle']}>
            <Star3Icon />
          </div>
          <div className={styles['main__text-container']}>
            <Typography variant="h3" className={styles['main__title']} weight="bold">
              Disponibilidade
            </Typography>
            <Typography variant="body-text-2" className={styles['main__subtitle']}>
              Tenha acesso a mais de 80 mil itens originais disponíveis.
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Features };
