import { useEffect, useState } from 'react';
import { selectedCarPlateState, selectedVehicleState } from '~/state/customer';
import { MakeForm, ModelForm, VersionForm } from '.';

export const VehicleSelector = ({ back, select }) => {
  const [make, setMake] = useState<string>();
  const [model, setModel] = useState<string>();
  const [version, setVersion] = useState<string>();
  const [year, setYear] = useState<string>();

  const selectCar = async () => {
    if (!year || !version || !make || !model) return;
    const selectedVehicle = {
      make,
      model,
      version,
      year,
    };
    selectedCarPlateState(null);
    selectedVehicleState(selectedVehicle);
    select(selectedVehicle);
  };

  useEffect(() => {
    setYear(undefined);
    setVersion(undefined);
  }, [model]);

  if (!make) return <MakeForm selectMake={setMake} back={back} />;
  if (!model) return <ModelForm make={make} selectModel={setModel} back={() => setMake(undefined)} />;
  return (
    <VersionForm
      make={make}
      model={model}
      year={year}
      selectVersion={setVersion}
      selectYear={setYear}
      back={() => setModel(undefined)}
      selectCar={selectCar}
    />
  );
};

export default VehicleSelector;
