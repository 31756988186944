import { Typography } from '@objectedge/pecai-storefront-ds';
import clsx from 'clsx';
import LinkIf from '~/utils/LinkIf';
import { RichTextProp } from '~/utils/prop-types';
import { RichText } from '~/utils/storyblok';
import styles from './Heading.module.scss';

export interface HeadingProps {
  text: String | RichTextProp;
  linkText?: String;
  link?: String;
  alignment?: 'center' | 'left' | 'right';
}

export const Heading = ({ text, linkText, link, alignment = 'left' }: HeadingProps) => {
  const renderText = () => {
    if (typeof text === 'string') return <>{text}</>;
    return <RichText content={text} />;
  };

  return (
    <div className={clsx('container p-sm-0 w-100', styles.heading, styles[alignment])}>
      <Typography variant="h2" className="d-flex flex-column">
        {renderText()}
      </Typography>
      {linkText && (
        <LinkIf url={link}>
          <Typography color="gray-400" variant="body-text-2">
            <u>{linkText}</u>
          </Typography>
        </LinkIf>
      )}
    </div>
  );
};

export default Heading;
