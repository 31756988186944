import { useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers';
import Loader from '~/components/common/Loader';
import {
  ArrowRightCircleIcon,
  Button,
  Color,
  Input,
  TextfieldCommonMasks,
  Typography,
} from '@objectedge/pecai-storefront-ds';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAlerts } from '~/utils/useAlerts';
import { selectedVehicleState, selectedCarPlateState } from '~/state/customer';
import { getVehicleName } from '~/utils/vehicle';
import Yup from '~/utils/yup';
import { VehicleSelector, PreFilledVersionForm } from './subcomponents';
import styles from './SearchByVehicleOrPlate.module.scss';
import { getReCaptchaToken, ReCaptchaActions } from '~/utils/reCaptcha';
import { vehicleSearchDefaultErrorMessage, vehicleSearchRecaptchaErrorMessage } from '~/utils/vehicleSearchByPlate';
import { CatalogKindOfSearch, useGetVehicleDetailsByPlateLazyQuery, VehicleDetails } from '~/operations';
import GA from '~/utils/ga';

export interface GarageSelectorProps {
  onSearch?: (any?) => any;
}

const schema = Yup.object().shape({
  vehicle: Yup.string().required(),
});

export const SearchByVehicleOrPlate = ({ onSearch }: GarageSelectorProps) => {
  const savedCarPlate = useReactiveVar(selectedCarPlateState);
  const savedSelectedVehicle = useReactiveVar(selectedVehicleState);
  const { actions } = useAlerts();
  const [carPlateValue, setCarPlateValue] = useState(savedCarPlate ? savedCarPlate?.plate : '');
  const [vehicleValue, setVehicleValue] = useState(selectedVehicleState);

  const [vehicleSelector, showVehicleSelector] = useState(false);
  const [versionSelectorVehicles, setVersionSelectorVehicles] = useState<VehicleDetails | undefined>({});
  const [loadingCarPlate, setLoadingCarPlate] = useState(false);
  const [carPlateErrorMessage, setCarPlateErrorMessage] = useState('');
  const [openPrefilledModal, setOpenPrefilledModal] = useState(false);

  const { control, errors, handleSubmit, setValue, clearErrors } = useForm({
    defaultValues: {
      vehiclePlate: savedCarPlate ? savedCarPlate?.plate : '',
      vehicle: savedSelectedVehicle ? getVehicleName(savedSelectedVehicle) : '',
    } as Record<string, any>,
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });

  const [fetchVehiclePlate, { data, loading }] = useGetVehicleDetailsByPlateLazyQuery();

  const impression = useCallback((searchText) => {
    try {
      GA.searchCar(searchText);
    } catch (e) {}
  }, []);

  const search = (vehicle) => {
    if (vehicle?.make) {
      onSearch && onSearch({ type: CatalogKindOfSearch.VehicleDetail, value: vehicle });
    } else if (vehicleValue?.make) {
      onSearch && onSearch({ type: CatalogKindOfSearch.VehicleDetail, value: vehicleValue });
    } else {
      onSearch && onSearch({ type: CatalogKindOfSearch.Plate, value: { name: carPlateValue, ...vehicle } });
    }
  };

  const selectVehicle = async (vehicle) => {
    setValue('vehicle', getVehicleName(vehicle));
    setVehicleValue(vehicle);
    setCarPlateValue('');
    await search(vehicle);
    showVehicleSelector(false);
  };

  const selectVehicleByPLate = async (vehicle) => {
    setVehicleValue('');
    await search(vehicle);
    setVersionSelectorVehicles({});
  };

  useEffect(() => {
    setVersionSelectorVehicles(data?.vehicleDetailsByPlate?.edge || {});
  }, [data]);

  const handleSearchVehicleByPlate = async (plate) => {
    impression(plate);
    setLoadingCarPlate(true);
    setCarPlateValue(plate);
    try {
      setCarPlateErrorMessage('');
      const reCaptchaToken = await getReCaptchaToken(ReCaptchaActions.vehicleSearchByPlate);
      if (!!reCaptchaToken) {
        fetchVehiclePlate({
          variables: { plate },
          context: { captcha: reCaptchaToken },
        });
        setOpenPrefilledModal(true);
        setVehicleValue('');
        setValue('vehicle', '');
        setLoadingCarPlate(false);
        // If vehicleSearch returns only 1 car, select it automatically
      } else {
        setLoadingCarPlate(false);
        setCarPlateErrorMessage(vehicleSearchRecaptchaErrorMessage);
      }
    } catch (e) {
      setLoadingCarPlate(false);
      if (e instanceof Error && e.message === 'Too many requests') {
        setCarPlateErrorMessage(vehicleSearchRecaptchaErrorMessage);
      } else {
        setCarPlateErrorMessage(vehicleSearchDefaultErrorMessage);
      }
    }
  };

  useEffect(() => {
    // Update values in case we change in another place (like the header)
    setValue('carPlate', savedCarPlate ? savedCarPlate?.plate : '');
    setCarPlateValue(savedCarPlate ? savedCarPlate?.plate : '');
  }, [savedCarPlate]);

  useEffect(() => {
    if (versionSelectorVehicles) {
      if (Object.keys(versionSelectorVehicles).length) {
        if (!versionSelectorVehicles?.models?.length) {
          setValue('carPlate', '');
          actions.addDangerAlert(
            'Infelizmente, não encontramos uma referência para a placa pesquisada. Digite novamente ou realize uma nova busca nos outros campos de pesquisa!'
          );
        }
      }
    }
  }, [versionSelectorVehicles]);

  if (vehicleSelector) {
    return <VehicleSelector back={() => showVehicleSelector(false)} select={selectVehicle} />;
  }
  if (
    openPrefilledModal &&
    Object.keys(versionSelectorVehicles || {}).length &&
    versionSelectorVehicles?.models?.length
  ) {
    return (
      // Version selector that uses the response from vehicle plate search
      <PreFilledVersionForm
        vehicles={versionSelectorVehicles}
        back={() => {
          setValue('carPlate', '');
          setCarPlateValue('');
          setOpenPrefilledModal(false);
        }}
        selectVehicle={selectVehicleByPLate}
      />
    );
  }

  return (
    <div className={styles['form__row']}>
      <Controller
        control={control}
        name="carPlate"
        render={(props) => (
          <Input
            {...props}
            mask={TextfieldCommonMasks.carPlate}
            className={styles['form__input']}
            label="Placa do veículo"
            status={carPlateErrorMessage ? 'danger' : 'default'}
            helperText={carPlateErrorMessage ?? 'Usaremos a placa para encontrar seu veículo.'}
            onChange={(e) => {
              props.onChange(e);
              clearErrors('vehicle');
              if (e.target.value.match(/[a-zA-Z]{3}[0-9][0-9a-zA-Z][0-9][0-9]/)) {
                handleSearchVehicleByPlate(e.target.value);
              }
            }}
            inputProps={{ className: 'text-uppercase' }}
          />
        )}
      />

      {(loadingCarPlate || loading) && (
        <div className={styles.loaderContainer}>
          <Loader constrained />
        </div>
      )}

      <div className={styles['form__text']}>
        <Typography>ou</Typography>
      </div>

      <div
        className="cursor-pointer"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setValue('carPlate', '');
          setCarPlateValue('');
          showVehicleSelector(true);
        }}
      >
        <Controller
          control={control}
          as={Input}
          label="Modelo"
          name="vehicle"
          className={styles['form__input']}
          icon={<ArrowRightCircleIcon size={24} />}
          status={errors.vehicle ? 'danger' : 'default'}
          helperText={errors.vehicle ? 'Campo obrigatório' : ''}
        />
      </div>

      <div className={styles['form__button']}>
        <Button id="vehicleFindServices" color={Color.PRIMARY} block onClick={handleSubmit(search)}>
          Nova Busca
        </Button>
      </div>
    </div>
  );
};

export default SearchByVehicleOrPlate;
