import styles from './Promotions.module.scss';
import { PromotionsList } from './PromotionsList';
import { getMediaPath } from '~/utils/getPublicPath';
import Link from 'next/link';

const Promotions = () => {
  return (
    <div className={styles['main']}>
      {PromotionsList.map((promotion) => (
        <Link href={promotion.url} key={promotion.key}>
          <a>
            <picture>
              <source
                srcSet={getMediaPath(`/media/home/${promotion.image}`)}
                type="image/png"
                media="all and (max-width:767px)"
              />
              <source
                srcSet={getMediaPath(`/media/home/${promotion.largeImage}`)}
                type="image/png"
                media="all and (min-width:768px) and (max-width:1024px)"
              />

              {/* Default Image */}
              <img src={getMediaPath(`/media/home/${promotion.image}`)} className={styles['main__image']} alt="ad" />
            </picture>
          </a>
        </Link>
      ))}
    </div>
  );
};

export { Promotions };
