import { ProductCard } from '@objectedge/pecai-storefront-ds';
import Link from 'next/link';
import styles from './FeaturedProducts.module.scss';

export const FeaturedProducts = ({ config }) => {
  try {
    const productsData = JSON.parse(config);

    return (
      <div className={`container ${styles.main}`}>
        {productsData.map((product) => (
          <Link href={product.pDPPath}>
            <a className="text-decoration-none">
              <ProductCard
                key={product.name}
                upperTag={product.upperTag}
                image={product.image}
                name={product.name}
                description={product.description}
                listPrice={product.listPrice}
                price={product.price}
                priceTag={product.interestMessage}
                // addToCartFunction={() => handleAddToCart(product.productId)}
              />
            </a>
          </Link>
        ))}
      </div>
    );
  } catch (e) {
    return null;
  }
};

export default FeaturedProducts;
