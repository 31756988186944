import { getMediaPath } from '~/utils/getPublicPath';
import styles from './Advertisement.module.scss';

type Props = {
  images: { desktop: string; mobile: string; tablet?: string };
  link?: string;
  target?: '_blank' | '_self' | '_parent' | '_top' | '_unfencedTop';
};

const Advertisement = ({ images, link, target = '_self' }: Props) => {
  const defaultImage = images.mobile || images.tablet || images.desktop;
  return (
    <a className={styles['main']} href={link} target={target}>
      <picture>
        {images.mobile && <source srcSet={images.mobile} type="image/png" media="all and (max-width:576px)" />}
        {images.tablet && (
          <source srcSet={images.tablet} type="image/png" media="all and (min-width:577px) and (max-width:1200px)" />
        )}
        {images.desktop && <source srcSet={images.desktop} type="image/png" media="all and (min-width:1201px)" />}
        <img src={defaultImage} className={styles['main__image']} alt="ad" />
      </picture>
    </a>
  );
};

export { Advertisement };
