import { useApolloClient } from '@apollo/client';
import Router, { useRouter } from 'next/router';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import {
  CatalogKindOfSearch,
  CatalogSearchWithZipcodeDocument,
  CatalogSearchWithZipcodeQuery,
  useGetCategoryListQuery,
} from '~/operations';
import Loader from '../common/Loader';
import { AuthenticationContext } from './Authentication';

interface SearchInterface {
  searchData: any;
  currentPage: number;
  catalogSearch: (page: number, selectedFilters?: FilterParam[]) => void;
  applySearch: (page: number, selectedFilters: FilterParam[]) => void;
  isLoadingPage: boolean;
}

type FilterParam = {
  id: string;
  type: string;
};

export const SearchContext = createContext<SearchInterface>({} as any);

interface SearchProps {
  isCategory?: Boolean;
  children: ReactNode;
}

export const Search = ({ isCategory = false, children }: SearchProps) => {
  const { provider } = useContext(AuthenticationContext);
  const { data: categoryListData } = useGetCategoryListQuery({ fetchPolicy: 'cache-and-network' });
  const router = useRouter();
  const {
    searchType,
    searchValue,
    groupProducts,
    brands,
    plate,
    systems,
    pageIndex,
    key,
    engine,
    model,
    year,
    version,
    make,
  } = router.query;
  const client = useApolloClient();
  const [searchData, setSearchData] = useState<any>();
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function get() {
      await catalogSearch();
    }

    if (router.isReady && categoryListData) get();
  }, [router.isReady, router.query, categoryListData]);

  const buildVeihicleParameter = () => {
    if (!make && !model && !year && !version) {
      return null;
    }

    const vehicle = { make, model, year: parseInt(year as string), version };

    return Object.keys(vehicle)
      .filter((key) => !!vehicle[key])
      .reduce((query, key) => {
        query[key] = vehicle[key];
        return query;
      }, {});
  };

  const catalogSearch = async () => {
    setIsLoadingPage(true);
    try {
      let selectedFilters: FilterParam[] = [];

      if (groupProducts) selectedFilters.push({ id: groupProducts.toString(), type: 'GROUP_PRODUCT' });

      if (systems) selectedFilters.push({ id: systems.toString(), type: 'SYSTEM' });

      if (brands) selectedFilters.push({ id: brands.toString(), type: 'BRAND' });

      if (key) {
        let category = categoryListData?.categoryList?.[0]?.children?.find((category) => category?.url_key === key);
        let extRef = category?.external_reference;
        if (extRef) {
          selectedFilters.push({ id: extRef.toString(), type: 'SYSTEM' });
        }
      }

      const vehicleDetail = buildVeihicleParameter();
      const zipcode = parseInt(provider?.address?.postalCode.replace('-', '') || '');
      const res = await client.query<CatalogSearchWithZipcodeQuery>({
        query: CatalogSearchWithZipcodeDocument,
        variables: {
          input: {
            query: searchValue ?? '',
            zipcode,
            plate,
            filters: selectedFilters,
            vehicleDetail: year ? vehicleDetail : undefined,
          },
          page: Number(pageIndex) || 0,
          limit: 24,
        },
      });

      setIsLoadingPage(false);
      setSearchData(res.data.catalogSearchWithZipcode);
      setCurrentPage(Number(pageIndex) || 0);
    } catch (error) {
      setIsLoadingPage(false);
    }
  };

  const applySearch = (page: number, selectedFilters?: FilterParam[]) => {
    let queryString = isCategory
      ? `/categoria/${key}?searchType=${CatalogKindOfSearch.Product}${searchValue ? `&searchValue=${searchValue}` : ''}`
      : `/busca?searchType=${searchType}${searchValue ? `&searchValue=${searchValue}` : ''}`;
    const groupProduct = selectedFilters?.find((item) => item?.type === 'GROUP_PRODUCT');
    const system = selectedFilters?.find((item) => item?.type === 'SYSTEM');
    const brand = selectedFilters?.find((item) => item?.type === 'BRAND');

    if (plate) {
      queryString += `&plate=${plate}`;
    }
    if (groupProduct) {
      queryString += `&groupProducts=${groupProduct?.id}`;
    }
    if (system) {
      queryString += `&systems=${system?.id}`;
    }
    if (brand) {
      queryString += `&brands=${brand?.id}`;
    }
    if (make) {
      queryString += `&make=${make}`;
    }
    if (version) {
      queryString += `&version=${version}`;
    }
    if (year) {
      queryString += `&year=${year}`;
    }
    if (model) {
      queryString += `&model=${model}`;
    }
    if (engine) {
      queryString += `&engine=${engine}`;
    }
    if (page) {
      queryString += `&pageIndex=${page}`;
    }

    Router.push(queryString);
  };

  return (
    <SearchContext.Provider value={{ searchData, currentPage, catalogSearch, applySearch, isLoadingPage }}>
      {children}
      {isLoadingPage && <Loader full />}
    </SearchContext.Provider>
  );
};
