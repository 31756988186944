import Head from 'next/head';

interface SeoTagsProps {
  title?: string;
  description?: string;
  imgUrl?: string;
}

export const SeoTags = ({ title, imgUrl, description }: SeoTagsProps) => {
  return (
    <Head>
      {title && <title>{title}</title>}
      {title && <meta name="title" content={title} />}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={description} />
      <meta property="og:image" content={imgUrl || ''} />
      <meta property="og:image:width" content="300" />
      <meta property="og:image:height" content="300" />
      <meta property="og:url" content={(typeof window !== 'undefined' && window.location.href) || ''} />
      <meta property="og:description" content={description} />
    </Head>
  );
};

export default SeoTags;
