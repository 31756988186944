import { useCallback } from 'react';
import Loader from '~/components/common/Loader';
import { ArrowLeftCircleIcon, Input, Typography } from '@objectedge/pecai-storefront-ds';
import { useState } from 'react';
import { useGetVehicleModelsQuery } from '~/operations';
import styles from '../SearchByVehicleOrPlate.module.scss';
import { ImageViewer } from '~/components/common/ImageViewer';
import { getMediaPath } from '~/utils/getPublicPath';
import GA from '~/utils/ga';

const modelImageURL = process.env.NEXT_PUBLIC_MODEL_IMAGE_URL;
const fallbackSrc = getMediaPath('/media/home/placeholder/car-78x52.png');

export const ModelForm = ({ make, selectModel, back }) => {
  const { data, loading } = useGetVehicleModelsQuery({ variables: { makeName: make, useThirdPartyApi: true } });
  const [filter, setFilter] = useState<string>('');
  const models = (data?.modelsList || []).filter((m) => m.toLowerCase().includes(filter.toLowerCase()));

  const impression = useCallback((searchText) => {
    try {
      GA.searchCar(searchText);
    } catch (e) {}
  }, []);

  return (
    <div className={styles.makeForm}>
      <div className={styles.header}>
        <ArrowLeftCircleIcon size={24} onClick={back} />
        <Typography variant="h3">Escolha o Modelo</Typography>
      </div>
      <Input
        label="Buscar modelos"
        onChange={({ target }) => setFilter(target.value)}
        onBlurCapture={() => filter && impression(filter)}
      />
      {loading ? (
        <div className={styles.loader}>
          <Loader constrained />
        </div>
      ) : (
        <div className={styles.makeList}>
          {models.map((model) => {
            const fileName = `${make.toLowerCase().replace(/\s+|[,\/]/g, '')}-${model
              .toLowerCase()
              .replace(/\s+|[,\/]/g, '')}78_52.png`;
            const src = `${modelImageURL}/${fileName}`;
            return (
              <div className={styles.makeRoot} onClick={() => selectModel(model)} key={model}>
                <ImageViewer src={src} fallbackSrc={fallbackSrc} alt={`${make} ${model}`} />
                <Typography variant="body-text-2">{model}</Typography>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ModelForm;
