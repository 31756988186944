import clsx from 'clsx';
import styles from './WhatsappCta.module.scss';
import { WhatsappCtaIcon } from '~/components/icons';

const WhatsappCta = () => {
    const openWhatsappChat = () => {
        return window.open(process.env.WHATS_URL, '_blank');
    }

    return (
        <button className={clsx(styles.whatsappcta)} role="link" aria-label="Fale conosco no whatsapp" onClick={() => openWhatsappChat()}>
            <WhatsappCtaIcon />
        </button>
    )
}

export default WhatsappCta;