import { makePersistentVar } from '~/utils/variables';

export type carPlateStateType = {
  plate: string;
  make: string;
  madeYear: string;
  model: string;
  engine: string;
};
export const selectedVehicleState = makePersistentVar<any>('selected-vehicle');
export const selectedCarPlateState = makePersistentVar<null | undefined | carPlateStateType>('selected-car-plate');
export const selectedPart = makePersistentVar<String | null | undefined>('selected-part');

export const registerEmail = makePersistentVar<String>('registerEmail');
