import { HeaderDesktop } from './HeaderDesktop';
import { HeaderMobile } from './HeaderMobile';
import { RichTextProp, StoryBlokEditable } from '~/utils/prop-types';
import Stepper from './Stepper';

export interface HeaderProps extends StoryBlokEditable {
  minimalist?: boolean;
  stepper?: boolean;
  yellowBandText?: string | RichTextProp;
  darkBandText?: string | RichTextProp;
  showDarkBand?: boolean;
  showYellowBand?: boolean;
}

export const Header = ({
  minimalist,
  stepper = false,
  darkBandText,
  yellowBandText,
  showDarkBand = false,
  showYellowBand = false,
  storyEditable,
}: HeaderProps) => {
  return (
    <>
      <header {...storyEditable} className="sticky-top">
        <HeaderDesktop
          className={`d-none d-lg-block`}
          darkBandText={darkBandText}
          yellowBandText={yellowBandText}
          showDarkBand={showDarkBand}
          showYellowBand={showYellowBand}
        />
        <HeaderMobile
          className={`d-lg-none`}
          darkBandText={darkBandText}
          yellowBandText={yellowBandText}
          showDarkBand={showDarkBand}
          showYellowBand={showYellowBand}
        />
      </header>
      {minimalist && stepper && <Stepper />}
    </>
  );
};

export default Header;
