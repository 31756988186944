import styles from './SearchParts.module.scss';
import { Tabs, Size } from '@objectedge/pecai-storefront-ds/src/components';
import { useState } from 'react';
import SearchByVehicleOrPlate from '~/components/forms/SearchByVehicleOrPlate';
import { SearchByPart } from '~/components/forms/SearchByPart';
import { Breakpoint, useBreakpoint } from '~/utils/display';
import { selectedVehicleState, selectedCarPlateState, selectedPart } from '~/state/customer';
import { useEffect } from 'react';
export interface GarageSelectorProps {
  onSearch?: (any?) => any;
  tab?: number;
}

const SearchParts = ({ onSearch = () => {}, tab = 1 }: GarageSelectorProps) => {
  const isMobileView = useBreakpoint({ max: Breakpoint.sm });
  const [activeTab, setActiveTab] = useState(1);

  const tabs = [
    { key: 1, label: isMobileView ? 'CARRO' : 'PROCURAR POR CARRO' },
    { key: 2, label: isMobileView ? 'PEÇA' : 'PROCURAR POR PEÇA' },
  ];
  const tabSwitch = (index) => {
    setActiveTab(index);
    selectedCarPlateState(null);
    selectedVehicleState('');
    selectedPart('');
  };
  useEffect(() => {
    setActiveTab(tab);
  }, [tab]);
  return (
    <div className={styles['main']}>
      <Tabs size={Size.SM} tabs={tabs} active={activeTab} onClick={tabSwitch} className={styles['main__tabs']} />
      {activeTab === 1 && <SearchByVehicleOrPlate onSearch={onSearch} />}
      {activeTab === 2 && <SearchByPart onSearch={onSearch} />}
    </div>
  );
};

export { SearchParts };
