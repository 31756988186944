import clsx from 'clsx';
import { DynamicComponent } from '../StoryBloks';
import styles from './Grid.module.scss';

export const Grid = ({ items, maxItems, columnsDesktop, columnsMobile }) => {
  if (!items?.length) return null;

  return (
    <div
      className={clsx(
        'container px-sm-0 my-4',
        styles.grid,
        styles['desktop-' + columnsDesktop],
        styles['mobile-' + columnsMobile],
        styles['hide-after-' + maxItems]
      )}
    >
      {items.map((blok) => (
        <DynamicComponent key={blok._uid} blok={blok} noWrapper />
      ))}
    </div>
  );
};

export default Grid;
