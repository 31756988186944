import { useCallback } from 'react';
import Loader from '~/components/common/Loader';
import { ArrowLeftCircleIcon, Input, Typography } from '@objectedge/pecai-storefront-ds';
import { useState } from 'react';
import { useGetMakesQuery } from '~/operations';
import { ImageViewer } from '~/components/common/ImageViewer';
import { getMediaPath } from '~/utils/getPublicPath';
import styles from '../SearchByVehicleOrPlate.module.scss';
import GA from '~/utils/ga';

const makesImageURL = process.env.NEXT_PUBLIC_MAKES_IMAGE_URL;
const fallbackSrc = getMediaPath('/media/home/placeholder/make-78x52.png');

export const MakeForm = ({ back, selectMake }) => {
  const { data, loading } = useGetMakesQuery({
    variables: {
      useThirdPartyApi: true,
    },
  });
  const [filter, setFilter] = useState<string>('');
  const makes = (data?.makesList || []).filter((m) => m.toLowerCase().includes(filter.toLowerCase()));

  const impression = useCallback((searchText) => {
    try {
      GA.searchCar(searchText);
    } catch (e) {}
  }, []);

  return (
    <div className={styles.makeForm}>
      <div className={styles.header}>
        <ArrowLeftCircleIcon size={24} onClick={back} />
        <Typography variant="h3">Escolha o Fabricante</Typography>
      </div>
      <Input
        label="Buscar marcas"
        onChange={({ target }) => setFilter(target.value)}
        onBlurCapture={() => filter && impression(filter)}
      />
      {loading ? (
        <div className={styles.loader}>
          <Loader constrained />
        </div>
      ) : (
        <div className={styles.makeList}>
          {makes.map((make) => {
            const fileName = `${make.toLowerCase().replace(/\s+|[,\/]/g, '')}78_52.png`;
            const src = `${makesImageURL}/${fileName}`;
            return (
              <div className={styles.makeRoot} onClick={() => selectMake(make)} key={make}>
                <ImageViewer src={src} fallbackSrc={fallbackSrc} alt={make} />
                <Typography variant="body-text-2">{make}</Typography>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MakeForm;
