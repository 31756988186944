import { getMediaPath } from '~/utils/getPublicPath';

export const PromotionsList = [
    {
        key: 1,
        image: '/bateria-promocao.png',
        largeImage: '/bateria_promocao_690x232.png',
        service: 'imagem de promocao de baterias para veículos',
        url: '/'
    },
    {
        key: 2,
        image: '/filtro-promocao.png',
        largeImage: '/filtro_promocao_690x232.png',
        service: 'imagem de promocao de filtro para veículos',
        url: '/'
    },
    {
        key: 3,
        image: '/pneu-promocao.png',
        largeImage: '/pneu_promocao_690x232.png',
        service: 'imagem de promocao de pneus para veículos',
        url: '/'
    }
]