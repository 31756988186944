import { Button, Color, Select, Typography } from '@objectedge/pecai-storefront-ds';
import { useCallback, useEffect, useState } from 'react';
import { selectedCarPlateState, selectedVehicleState } from '~/state/customer';
import styles from '../SearchByVehicleOrPlate.module.scss';

type Model = {
  label: string;
  value: string;
};
type Engine = {
  label: string;
  value: string;
};
export const PreFilledVersionForm = ({ vehicles, back, selectVehicle }) => {
  const { plate, brand, color, madeYear, models } = vehicles;

  const [selectedModel, setSelectedModel] = useState<Model>();
  const [selectedEngine, setSelectedEngine] = useState<Engine>();
  const [engineOptions, setEngineOptions] = useState<Array<Engine>>();
  const [errors, setErrors] = useState({ selectedEngine: '', selectedModel: '' });

  const modelOptions = models?.map((model) => {
    return { label: model?.model, value: model?.model };
  });

  const selectCar = useCallback(() => {
    if (!selectedModel) {
      setErrors({ ...errors, selectedModel: 'Select model' });
      return;
    }
    if (!selectedEngine) {
      setErrors({ ...errors, selectedEngine: 'Select engine' });
      return;
    }
    setErrors({ selectedEngine: '', selectedModel: '' });
    const selectedVehicle = { model: selectedModel?.label, engine: selectedEngine?.label };
    const selectedCarPlate = {
      plate,
      make: brand,
      madeYear,
      model: selectedModel?.value,
      engine: selectedEngine?.value,
    };
    selectedCarPlateState(selectedCarPlate);
    selectedVehicleState('');
    selectVehicle(selectedVehicle);
  }, [selectedModel, selectedEngine]);

  useEffect(() => {
    if (modelOptions.length === 1) {
      setSelectedModel({ label: modelOptions[0]?.label, value: modelOptions[0]?.value });
    }
  }, [models]);
  useEffect(() => {
    setEngineOptions(
      models
        ?.filter((model) => model?.model === selectedModel?.label)[0]
        ?.engines?.map((engine) => {
          return { label: engine || '', value: engine || '' };
        })
    );
    setSelectedEngine(undefined);
  }, [models, selectedModel]);
  useEffect(() => {
    if (engineOptions?.length === 1) setSelectedEngine(engineOptions[0]);
  }, [engineOptions]);

  return (
    <div className={styles.makeForm}>
      <div className={styles.header}>
        <Typography variant="h6" weight='bold'>Resultado da busca pela placa: {plate}</Typography>
      </div>
      <div className="d-flex mb-2">
        <Typography variant="body-text-1" className="mr-2" weight="bold">
          Marca:
        </Typography>
        <Typography variant="body-text-1">{brand}</Typography>
      </div>
      <div className="d-flex mb-2 mr-2">
        <Typography variant="body-text-1" className="mr-2" weight="bold">
          Ano de Fabricação:
        </Typography>
        <Typography variant="body-text-1">{madeYear}</Typography>
      </div>
      <div className="d-flex mb-2 mr-2">
        <Typography variant="body-text-1" className="mr-2" weight="bold">
          Cor:
        </Typography>
        <Typography variant="body-text-1">{color}</Typography>
      </div>
      <div>
        <Typography variant="body-text-1" weight="bold">
          Nome do Veículo:
        </Typography>
        <Select
          placeholder="Selecione"
          label="Selecione"
          options={modelOptions}
          onChange={(v: any) => {
            setSelectedModel(v);
            setErrors({ ...errors, selectedModel: '' });
          }}
          disabled={modelOptions?.length === 1}
          value={selectedModel}
          isSearchable={false}
          helperText={errors.selectedModel}
          status={errors.selectedModel ? 'danger' : undefined}
        />
      </div>
      <div>
        <Typography variant="body-text-1" weight="bold">
          Clindrada:
        </Typography>
        <Select
          placeholder="Selecione"
          label="Selecione"
          options={engineOptions}
          onChange={(v: any) => {
            setSelectedEngine(v);
            setErrors({ ...errors, selectedEngine: '' });
          }}
          disabled={!selectedModel || (selectedModel && engineOptions?.length === 1)}
          value={selectedEngine}
          isSearchable={false}
          helperText={errors.selectedEngine}
          status={errors.selectedEngine ? 'danger' : undefined}
          className="mb-2"
        />
      </div>
      <div className="d-flex flex-grow-1 mb-3">
        <Button color={Color.PRIMARY} block onClick={selectCar} className={styles['makeForm__btn']} disabled={false}>
          Confirmo os Dados do Veículo
        </Button>
      </div>
      <Button id="vehicleFindServices" color={Color.SECONDARY} outline block onClick={back}>
        Voltar
      </Button>
    </div>
  );
};

export default PreFilledVersionForm;
