const isProd = process.env.NODE_ENV === 'production';
const cdnHost = process.env.CDN_HOST;
const assetURL = process.env.ASSETS_URL;

export const getPublicPath = (path: string): string => {
  if (!isProd || !cdnHost) {
    return path;
  }

  return `${cdnHost}/_next/static/public${path}`;
};

export const getAssetPath = (path: string): string => {
  return `${assetURL}/${path}`;
};

export const getMediaPath = (path: string): string => cdnHost + path;
