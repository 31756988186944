import { Typography, Modal, Radio, Select, Input } from '@objectedge/pecai-storefront-ds';
import styles from './ProviderChangeModal.module.scss';
import { useState, useContext, useEffect, useMemo } from 'react';
import { AuthenticationContext, AuthenticationInterface } from '~/components/context/Authentication';

interface CategoryListProps {
  modalOpen: boolean;
  toggleModal: () => void;
  toggleMenu?: Function;
}

interface PaginationOpt {
  label: string;
  value: string;
}

const PAGE_SIZE = 5;

export const ProviderChangeModal = ({ modalOpen, toggleModal, toggleMenu }: CategoryListProps) => {
  const { setBuyer, user, provider } = useContext(AuthenticationContext);
  const [filterValue, setFilterValue] = useState('');
  const [page, setPage] = useState(0);
  const [selectedProvider, setSelectedProvider] =
    useState<NonNullable<AuthenticationInterface['user']>['buyers'][number]>();

  const buyersList = user?.buyers || [];

  const buildOption = (page: number): PaginationOpt => ({
    label: `Página ${page + 1} / ${maxPages}`,
    value: page.toString(),
  });

  const filteredProviders = useMemo(() => {
    const val = filterValue.toLowerCase();
    return buyersList?.filter((d) => d.tradingName.toLowerCase().includes(val) || d.cnpj.includes(val));
  }, [filterValue]);

  const currentPage = useMemo(() => {
    return filteredProviders.slice(page * PAGE_SIZE, page * PAGE_SIZE + PAGE_SIZE);
  }, [filteredProviders, page]);

  const maxPages = Math.ceil(filteredProviders.length / PAGE_SIZE);
  const paginationOptions: Array<PaginationOpt> = Array.from({ length: maxPages }, (_, i) => buildOption(i));

  const handleProviderChange = () => {
    if (selectedProvider) {
      setBuyer(selectedProvider);
      toggleModal();
      if (toggleMenu) toggleMenu();
    }
  };

  const handleInputChange = async (inputValue: string) => {
    setFilterValue(inputValue);
  };

  useEffect(() => {
    setFilterValue('');
    setPage(0);
  }, [modalOpen]);

  return (
    <Modal
      isOpen={modalOpen}
      title={`Troque por outra loja (atual selecionada: ${provider?.commercialName})`}
      cancelHandler={toggleModal}
      showOkButton={true}
      okHandler={handleProviderChange}
      okButtonLabel="TROCAR"
      showCancelButton={true}
      cancelButtonLabel="cancelar"
      size="lg"
      className={styles['main']}
    >
      <Input
        type="text"
        placeholder="Procurar por uma loja"
        onChange={(e) => handleInputChange(e.target.value)}
        helperText={'Os filtros utilizados são: razão social, nome fantasia e CNPJ.'}
      />
      {!filteredProviders?.length ? (
        <span>Nenhum registro encontrado.</span>
      ) : (
        currentPage?.map((org) => (
          <div className={styles['radio-item']} key={org?.id}>
            <Radio
              checked={selectedProvider === org}
              onClick={() => {
                setSelectedProvider(org);
              }}
              id={`provider-${org?.id}`}
              label={
                <Typography variant="body-text-1">
                  {org?.tradingName} ({org?.cnpj})
                </Typography>
              }
            />
          </div>
        ))
      )}
      <Select
        placeholder="Selecione"
        label="Selecione"
        options={paginationOptions}
        disabled={paginationOptions.length < 2}
        value={buildOption(page)}
        onChange={(option: any) => {
          option.value && setPage(parseInt(option.value));
        }}
        isSearchable={false}
        helperText={'Controle de paginação, selecione uma página.'}
      />
    </Modal>
  );
};

export default ProviderChangeModal;
