import Link from 'next/link';
import { Typography, MailIcon, PhoneCallIcon, WhatsIcon } from '@objectedge/pecai-storefront-ds';

export interface ContactSectionProps {
  className?: string;
}

const WhatsInfo = () => {
  return (
    <Typography variant="body-xsmall" className="d-flex align-items-center">
      <WhatsIcon size={24} className="mr-2" />
      (11) 98813-5570
    </Typography>
  );
};

const PhoneInfo = () => {
  return (
    <Link href="tel:01130034591">
      <a target="_blank">
        <Typography variant="body-xsmall" className="d-flex align-items-center">
          <PhoneCallIcon size={24} color="white" className="mr-2" />
          (11) 3003-4591
        </Typography>
      </a>
    </Link>
  );
};

export const ContactSection = ({ className }: ContactSectionProps) => {
  return (
    <div className={className}>
      <Typography variant="h6" className="mb-3">
        Contato
      </Typography>
      {process.env.WHATS_URL ? (
        <Link href={process.env.WHATS_URL}>
          <a target="_blank">
            <WhatsInfo />
          </a>
        </Link>
      ) : (
        <WhatsInfo />
      )}

      <Typography className="my-2 d-flex align-items-center" variant="body-xsmall">
        <MailIcon size={24} color="white" className="mr-2" />
        atendimento@pecaai.com.br
      </Typography>
      <PhoneInfo />
    </div>
  );
};

export default ContactSection;
