import { useCallback, useEffect } from 'react';
import styles from './FormBanner.module.scss';

import { Typography } from '@objectedge/pecai-storefront-ds/src/components';
import { getMediaPath } from '~/utils/getPublicPath';
import { SearchParts } from '~/components/marketing/SearchParts';
import Router from 'next/router';
import { selectedCarPlateState, selectedVehicleState } from '~/state/customer';
import { CatalogKindOfSearch } from '~/operations';

const FormBanner = () => {
  const Background = getMediaPath('/media/home/banner_home_parts.png');

  useEffect(() => {
    selectedVehicleState('');
    selectedCarPlateState(null);
  }, []);

  const onSearch = useCallback((searchParameters) => {
    if (searchParameters.type === CatalogKindOfSearch.Plate) {
      Router.push(
        `/busca?searchType=${searchParameters?.type}&searchValue=${searchParameters?.value?.name}&model=${searchParameters?.value?.model}&engine=${searchParameters?.value?.engine}`
      );
    } else if (searchParameters.type === CatalogKindOfSearch.VehicleDetail) {
      Router.push(
        `/busca?searchType=${searchParameters?.type}&searchValue=byVehicleDetail&make=${searchParameters?.value?.make}&model=${searchParameters?.value?.model}&version=${searchParameters?.value?.version}&year=${searchParameters?.value?.year}`
      );
    } else {
      Router.push(`/busca?searchType=${searchParameters?.type}&searchValue=${searchParameters?.value}`);
    }
  }, []);

  return (
    <div
      className={styles['main']}
      style={{
        backgroundImage: `linear-gradient(180deg, #333237 6.42%, #191714 100%)`,
      }}
    >
      <div className={styles['main__container']}>
        <div className={styles['main__text-container']}>
          <Typography variant="display-1" lookLike="h1" className={styles['main__title']} weight="bold">
            O maior estoque de peças com <br className={styles['main__title__br']} />
            entrega em até 2h em sua oficina
          </Typography>
        </div>
        <div className={styles['main__wrapper']}>
          <div className={styles['main__form']}>
            <SearchParts onSearch={onSearch} />
          </div>
        </div>
      </div>
    </div>
  );
};

export { FormBanner };
