import { Typography } from '@objectedge/pecai-storefront-ds';
import clsx from 'clsx';
import { useGetCategoryListQuery } from '~/operations';
import { Page, useNavigation } from '~/utils/navigation';
import styles from './Categories.module.scss';

const Categories = () => {
  const { data: providerServices } = useGetCategoryListQuery();
  const { goToPage } = useNavigation();

  return (
    <div className={clsx('container', styles.main)}>
      <Typography variant="h2" lookLike="h2" className={styles['main__title']} weight="bold">
        Principais categorias
      </Typography>

      <div className={styles['main__categories']}>
        {providerServices?.categoryList?.[0]?.children?.map((category) => {
          return (
            <div
              className={styles['main__category']}
              onClick={() => {
                goToPage(Page?.category, { id: category?.url_key });
              }}
            >
              <Typography className={styles['main__category-name']} weight="bold">
                {category?.name}
              </Typography>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { Categories };
