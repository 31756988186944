import { useContext } from 'react';
import { AuthenticationContext } from '~/components/context/Authentication';
import StoryBloks from '../StoryBloks';

export const LoggedInPortal = ({ content }) => {
  const { isLoggedIn } = useContext(AuthenticationContext);

  return isLoggedIn ? <StoryBloks bloks={content} /> : null;
};

export default LoggedInPortal;
