import Slider, { Settings } from 'react-slick';
import { DynamicComponent } from '~/components/structure';
import { Breakpoint, getBreakpoint } from '~/utils/display';
import styles from './Carousel.module.scss';

export interface CarouselProps {
  items: any[];
  slidesToShow?: number;
  slidesToShowMobile?: number;
  showDots?: boolean;
  showDotsMobile?: boolean;
  numberOfRows?: number;
  numberOfRowsMobile?: number;
  autoPlay?: number;
}

const getConfig = (props: CarouselProps): Settings => ({
  dots: props.showDots,
  infinite: true,
  slidesToShow: Number(props.slidesToShow),
  slidesToScroll: Number(props.slidesToShow),
  rows: Number(props.numberOfRows),
  autoplay: Number(props.autoPlay) > 0,
  autoplaySpeed: Number(props.autoPlay || 0) * 1000,
  responsive: [
    {
      breakpoint: getBreakpoint(Breakpoint.md),
      settings: {
        slidesToShow: Number(props.slidesToShowMobile),
        slidesToScroll: Number(props.slidesToShowMobile),
        rows: Number(props.numberOfRowsMobile),
        dots: props.showDotsMobile,
      },
    },
  ],
});

export const Carousel = (props: CarouselProps) => {
  if (!props.items?.length) return null;

  const config = getConfig(props);

  return (
    <div className={`${styles.carousel} container px-sm-0`}>
      <Slider className={styles.slider} {...config}>
        {props.items.map((blok) => (
          <DynamicComponent key={blok._uid} blok={blok} />
        ))}
      </Slider>
      {config.dots && <div className={styles.dotSpacer}></div>}
    </div>
  );
};

export default Carousel;
