import LinkIf from '~/utils/LinkIf';
import { ImageProp, LinkProp } from '~/utils/prop-types';
import styles from './FeaturedLogo.module.scss';

export interface FeaturedLogoProps {
  logo: ImageProp;
  link: LinkProp;
}

export const FeaturedLogo = ({ logo, link }: FeaturedLogoProps) => (
  <LinkIf url={link?.url}>
    <a className={styles.featuredLogo}>
      <img src={logo.filename} />
    </a>
  </LinkIf>
);

export default FeaturedLogo;
